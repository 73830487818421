/*! PROJECT */


function projectImagesReveal() {
	let mediaBlocks = document.querySelectorAll('.project-media-block');
	let mediaBlocksArray = Array.from(mediaBlocks);
	let allButFirst = mediaBlocksArray.slice(1); //skip first one, reveal is handled by heroReveal()
	// Initialize arrays to hold images and videos
	let images = [];
	let inlineVideos = [];

// Loop through each element in allButFirst
	allButFirst.forEach(block => {
		// Query for images within the current block
		let blockImages = block.querySelectorAll('img:not(.no-reveal)');
		images.push(...blockImages);

		// Query for videos within the current block
		let blockVideos = block.querySelectorAll('video');
		inlineVideos.push(...blockVideos);
	});

	let imagesArray = Array.from(images);
	let videosArray = Array.from(inlineVideos);

	let mediaArray = imagesArray.concat(videosArray);

	console.log(imagesArray)

	mediaArray.forEach(function(item) {
			gsap.from(item, {
				opacity: 0,
				scale: 1.2,
				ease: 'primary-ease',
				stagger: 0.2,
				duration: 0.8,
				scrollTrigger: {
					trigger: item,
					start: "top 70%",
					scrub: false,
					pin: false,
					/*markers: true*/
				},
			});
	})

	//full length videos have a video snippet overlay - user clicks = hide snippet, play feature video underneath
	mediaBlocks.forEach(function(thisBlock){
		if (thisBlock.getElementsByClassName('feature-video').length > 0) {
			thisBlock.style.position = 'relative';
			let teaser = thisBlock.querySelector('.video-teaser-overlay');
			let mainVid = thisBlock.querySelector('.main-video');

			teaser.addEventListener('click', () => {
				gsap.to(teaser, {
					onStart() {
						mainVid.muted = !mainVid.muted;
						mainVid.play();
					},
					duration: 0.3,
					autoAlpha: 0,
					ease: 'primary-ease'
				});
				cursor.classList.remove('active');
			});
		}
	})
}

function draggableImages() {
	let dragPic = document.querySelectorAll('.dragPic');

	dragPic.forEach(function (a, index) {
		let onLoadImgTL;
		let dragImg = a.querySelector('img');
		let parent = a.parentNode;
		let progressBar = parent.querySelector('.progress-bar > span');
		let containerWidth = gsap.getProperty(a, 'width');
		let imgWidth = gsap.getProperty(dragImg, 'width');
		let animateXTo = imgWidth - containerWidth;
		let lastWidth = window.innerWidth;
		let speed;

		if (a.hasAttribute("data-speed")) {
			speed = a.dataset.speed;
		} else {
			speed = 120;
		}

		window.addEventListener("resize", function () {
			//ios nav bar causes issue so have to detect width only resize here
			if (window.innerWidth !== lastWidth) {
				gsap.set(dragImg, {clearProps: 'all'});
				onLoadImgTL.kill();
				containerWidth = gsap.getProperty(a, 'width');
				imgWidth = gsap.getProperty(dragImg, 'width');
				animateXTo = imgWidth - containerWidth;
				playAni(-animateXTo);
			}
		});

		function playAni() {
			onLoadImgTL = gsap.timeline({paused: true});
			onLoadImgTL.to(dragImg, {
				x: -animateXTo,
				duration: speed,
				yoyo: true,
				delay: 1,
				repeat: -1,
				ease: 'none',
				onUpdate: () => {
					progressBarWidth()
				}
			})
			/*onLoadImgTL.play();*/


			ScrollTrigger.create({
				start: 0,
				end: "max",
				onUpdate: () => {
					if (ScrollTrigger.isInViewport(dragImg, 0.5)) {
						/*console.log('view now');*/
						onLoadImgTL.play();
					} else {
						onLoadImgTL.pause();
					}
				}
			});


		}

		function progressBarWidth() {
			let containerWidth = gsap.getProperty(a, 'width');
			let imgWidth = gsap.getProperty(dragImg, 'width');
			let xMax = animateXTo = imgWidth - containerWidth;
			let currPos = gsap.getProperty(dragImg, 'x')
			let progress = (currPos / xMax) * -1;
			gsap.to(progressBar, {scaleX: progress});
		}

		gsap.set(progressBar, {scaleX: 0})

		Draggable.create(dragImg, {
			type: "x",
			bounds: a,
			inertia: true,
			zIndexBoost: false,
			onDrag: () => {
				progressBarWidth();
				onLoadImgTL.kill();
			},
			onThrowUpdate: () => {
				progressBarWidth()
			},
			onDragStart: () => {
				cursor.classList.remove('active');
			}

		});

		playAni(-animateXTo);
	})
}

function dragPanImages() {
	let dragPanPic = document.querySelectorAll('.drag-pan-pic');


	dragPanPic.forEach(function (a, index) {
		let dragPanImage = a.querySelector('img');
		let dragBounds = dragPanImage.parentElement;

		gsap.set(dragPanImage, {xPercent: -50, yPercent: -50, left: "50%", top: "50%", position: 'relative'})

		Draggable.create(dragPanImage, {
			bounds: dragBounds,
			inertia: true,
			zIndexBoost: false,/*
			dragResistance:0.25,*/
			onDragStart: () => {
				cursor.classList.remove('active');
			},
			onDragEnd: () => {

			}
		});


	})

}

function videoInView() {

	let videos = document.querySelectorAll('video.inView-vid')

	if (videos.length > 0) {
		videos.forEach(function (video) {
			ScrollTrigger.create({
				start: 0,
				end: "max",
				onUpdate: inViewCheck
			});

			function inViewCheck() {
				if (ScrollTrigger.isInViewport(video, 0.5)) {
					video.play();
				} else {
					video.pause();
				}
			}
		});
	}
}
