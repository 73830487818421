/*! HOMEPAGE */

/* Create featured Grid*/
function createFeaturedGrid() {
	const selectedProjects = [
		PROJECT_DATA[1],
		PROJECT_DATA[11],
		PROJECT_DATA[6],
		PROJECT_DATA[8],
		PROJECT_DATA[0],
		PROJECT_DATA[2],
		PROJECT_DATA[5],
		PROJECT_DATA[12],
		PROJECT_DATA[9],
		PROJECT_DATA[7],
	]
	const projectsGridContainer = document.querySelector('.projects-grid')

	selectedProjects.forEach((projectItem, index) => {

		// Create a div for each portfolio item - .grid-item
		const itemDiv = document.createElement("div");
		itemDiv.className = "grid-item";

		//Create an anchor for each portfolio item
		const anchor = document.createElement("a");
		anchor.className = "local";
		anchor.href = projectItem.href;

		// Create a div to contain description
		const descDiv = document.createElement("div");
		descDiv.className = "description";

		// Create a h3 for the title
		const title = document.createElement("h3");
		title.textContent = projectItem.name;

		// Create a p for the desc
		const desc = document.createElement("p");
		desc.textContent = projectItem.description;

		// create div to contain image
		const imgDiv = document.createElement("div");
		const img = document.createElement("img");
		imgDiv.className = "image";
		img.src = projectItem.homeFeaturedImg;
		img.alt = projectItem.name;
		img.setAttribute('loading', 'lazy');

		//create videos for desktop hover
		const video = document.createElement('video');
		video.className = "video";
		video.muted = true;
		video.loop = true;
		video.playsInline = true;
		const videoSource = document.createElement('source');
		videoSource.src = projectItem.homeFeaturedVideo;
		videoSource.type = 'video/mp4';
		video.appendChild(videoSource);


		//assemble markup
		descDiv.appendChild(title);
		descDiv.appendChild(desc);
		imgDiv.appendChild(img);
		anchor.appendChild(imgDiv);
		anchor.appendChild(video);
		anchor.appendChild(descDiv);
		itemDiv.appendChild(anchor);

		projectsGridContainer.appendChild(itemDiv);

	})

	featuredGridInteraction();
	gridImgParallax();
}

function featuredGridInteraction() {
	mm.add("(min-width: 1024px)", () => {

		let gridItem = document.querySelectorAll('.grid-item');

		gridItem.forEach(function (item, index) {
			let image = item.querySelector('.image');
			let video = item.querySelector('.video');

			let hoverTl = gsap.timeline({
				paused: true, defaults: {ease: 'primary-ease'}
			});

			hoverTl.set(video, {scale: 1.4});

			hoverTl
					.to(image, {autoAlpha: 0, scale: 1.2}, 'sameTime')
					.to(video, {scale: 1.2}, 'sameTime')


			function handleMouseEnter() {
				if (video.paused) {
					video.play();
				}
				hoverTl.play();
			}

			function handleMouseLeave() {
				if (!video.paused) {
					video.pause();
				}
				hoverTl.reverse();
			}


			item.addEventListener("mouseenter", event => {
				handleMouseEnter();
			});

			item.addEventListener("mouseleave", event => {
				handleMouseLeave();
			});

		})
	});
}


/* Set Height of Ticker Text (abs pos makes container collapse) */
function setHeroTickerHeight() {
	const textTicker = homeHero.querySelector('#text-ticker');
	const firstLineHero = homeHero.querySelector('h1 > span:first-child');
	const textTickerList = document.querySelector('#text-ticker-list');
	let tickerItemHeight = textTickerList.children[0].getBoundingClientRect().height;
	/*console.log(tickerItemHeight);*/
	textTicker.style.height = tickerItemHeight + 'px';
	firstLineHero.style.height = tickerItemHeight + 'px';
}

/* Reveal Homepage on Initial Load */
function homePageReveal() {
	const homeTitle = homeHero.querySelector('h1 > span:not(#text-ticker)');
	const homeTitleChars = new SplitText(homeTitle, {type: "chars"});

	const designText = homeHero.querySelector('#text-ticker-list > span:first-child');
	const designTextChars = new SplitText(designText, {type: "chars"});

	const description = homeHero.querySelector('.description-text');
	const descriptionLines = new SplitText(description, {type: "lines"});
	new SplitText(description, {type: "lines", linesClass: "outer"});

	const disciplines = homeHero.querySelectorAll('.disciplines > li');
	const disciplinesLines = new SplitText(disciplines, {type: "lines"});

	const scrollDownText = homeHero.querySelector('.scroll');

	homeRevealTL = gsap.timeline({
		paused: true,
		onComplete: () => {
			homeTitleChars.revert();
			disciplinesLines.revert();
			descriptionLines.revert();
			designTextChars.revert();
			initHomeHeroTickerText();
		}, defaults: {ease: 'primary-ease'}
	});

	homeRevealTL
			.from(homeTitleChars.chars, {
				yPercent: 90,
				stagger: 0.04,
				duration: 0.7
			}, 'sameTime')
			.from(designTextChars.chars, {
				yPercent: 95,
				stagger: 0.04,
				duration: 0.7
			}, 'sameTime+=0.2')
			.from(descriptionLines.lines, {
				duration: 0.8,
				yPercent: 90,
				stagger: 0.1
			}, 'sameTime+=0.2')
			.from(disciplinesLines.lines, {
				duration: 0.8,
				yPercent: 90,
				stagger: 0.1
			}, 'sameTime+=0.2')
			.from(scrollDownText, {
				duration: 0.8,
				opacity: 0
			}, 'sameTime+=0.2')
}

/* Home Hero Ticker Text Animation */
function initHomeHeroTickerText() {
	const textTickerList = document.querySelector('#text-ticker-list');
	const textTickerItems = textTickerList.querySelectorAll('span');
	textTickerItems.forEach((word) => {
		new SplitText(word, {type: "chars", charsClass: "chars"});
	})

	let aniLettersAll = [];
	for (let i = 0; i < textTickerItems.length; i++) {
		let count = i + 1;
		let animatedChars = textTickerList.querySelectorAll('span:nth-of-type(' + count + ') > .chars');
		aniLettersAll.push(animatedChars);
	}
	textAniTL = gsap.timeline({
		paused: true,
		repeat: -1,
		repeatRefresh: true,
		onRepeat: function () {
			clearProps()
		}
	});

	function clearProps() {
		for (let i = 0; i < textTickerItems.length; i++) {
			gsap.set(aniLettersAll[i], {clearProps: "transform"});
		}
	}

	const ease = 'primary-ease'
	const stagger = 0.04
	const duration = 0.7
	const delay = 1

	textAniTL
			.to(aniLettersAll[0], {y: '-100%', stagger: stagger, duration: duration, delay: delay, ease: ease}, 'sameTime')
			.to(aniLettersAll[1], {y: '-100%', stagger: stagger, duration: duration, delay: delay, ease: ease}, 'sameTime')

	for (let i = 0; i < textTickerItems.length - 2; i++) {
		let count = i + 1;
		textAniTL.to(aniLettersAll[count + 1], {
			y: '-100%',
			stagger: stagger,
			duration: duration,
			delay: delay,
			ease: ease
		}, 'sameTime' + count)
		textAniTL.to(aniLettersAll[count], {
			y: '-200%',
			stagger: stagger,
			duration: duration,
			delay: delay,
			ease: ease
		}, 'sameTime' + count)
	}

	textAniTL.play();

}

/* Home Hero Scroll Text Effect */
function initHomeHeroScroll() {
	const title = homeHero.querySelector('h1');
	const description = homeHero.querySelector('.description');
	const gridItems = homeFeaturedGrid.querySelectorAll('.grid-item');
	const gridTitle = homeFeaturedGrid.querySelector('.title > h2');
	const scrollDownText = homeHero.querySelector('.scroll');
	//concealing the home hero when user scrolls (blur effect etc)
	const homeHeroTL = gsap.timeline({
		scrollTrigger: {
			trigger: homeFeaturedGrid,
			start: "top 100%",
			end: "top 20%",
			scrub: 0.2,
			pin: false,
			/*markers: true,*/
			onLeave: () => {
				homeHero.classList.add('disable');
				if (textAniTL) {
					textAniTL.pause()
				}
			},
			onEnterBack: () => {
				homeHero.classList.remove('disable')
				if (textAniTL) {
					textAniTL.play()
				}
			},
		}
	});

	homeHeroTL
			.to([scrollDownText, description, title], {
				y: -100,
				transformOrigin: 'bottom',
				filter: "blur(10px)",
				opacity: 0,
				ease: 'power2.in',
				stagger: 0.1
			})

	//staggered reveal for each grid item
	new SplitText(gridTitle, {type: "chars", charsClass: "outer"});
	let featuredTitleChars = new SplitText(gridTitle, {type: "chars"});
	gsap.from(featuredTitleChars.chars, {
		duration: 0.8,
		yPercent: 90,
		stagger: 0.1,
		scrollTrigger: {
			trigger: homeProjectsGrid,
			start: 'top 50%',
			end: 'top 5%',
			toggleActions: 'play none play reverse',
			scrub: true,
			/*markers: true,*/
		}
	})

	gridItems.forEach(function (item, index) {
		let stagger = (index + 1) * 100;
		gsap.fromTo(item,
				{
					y: stagger,
					opacity: 0,
				},
				{
					y: 0,
					opacity: 1,
					duration: 1,
					ease: 'none',
					stagger: 2,
					scrollTrigger: {
						trigger: homeProjectsGrid,
						start: 'top 50%',
						end: 'top 5%',
						toggleActions: 'play none play reverse',
						scrub: true,
						/*markers: true,*/
					}
				}
		);
	})
}


function gridImgParallax() {
	//image parallax
	gsap.utils.toArray(".grid-item img").forEach((img, i) => {
		// Create a new div to wrap the image for the effect
		const wrapper = document.createElement('div');
		wrapper.classList.add('img-wrapper'); // Add a class for styling if needed
		img.parentNode.insertBefore(wrapper, img);
		wrapper.appendChild(img);
		const randomFloat = Math.random() + 1;
		const multiplyer = 30

		/*console.log(-randomFloat * multiplyer)*/

		gsap.fromTo(img, {
			y: -randomFloat * multiplyer
		}, {
			y: randomFloat * multiplyer,
			ease: "none",
			scrollTrigger: {
				trigger: img,
				scrub: true,
				start: "top bottom",
				end: "bottom top",
				/*markers:true*/
			}
		});
	});
}

/* Home Hero Click to Scroll */
function initHomeHeroClickScroll() {
	homeHero.addEventListener('click', () => {
		gsap.to(window, {
			duration: 1.5,
			ease: 'power2.out',
			scrollTo: homeFeaturedGrid
		});
	});
}
