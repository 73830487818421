function contactOverlay() {

	const contactScreen = document.getElementById('contact-overlay');
	const closeBtn = contactScreen.querySelector('.close');
	const closeBtnInner = closeBtn.querySelector('div');
	const title = contactScreen.querySelector('.title');
	const message = contactScreen.querySelector('.message');
	const links = contactScreen.querySelectorAll('.links a');
	const bg = contactScreen.querySelector('.bg');
	const contactBtn = document.querySelectorAll('.show-contact');

	new SplitText(title, {type: "words"});
	let splitTextTitle2 = new SplitText(title, {type: "words"});

	new SplitText(message, {type: "words"});
	let splitTextMessage2 = new SplitText(message, {type: "words"});

	gsap.set(contactScreen, {display: 'none'})

	let contactRevealTL = gsap.timeline({
		paused: true,
		defaults: {ease: 'primary-ease'},
		onStart: () => {
			contactScreen.classList.add('active');
		},
	});

	contactRevealTL
			.set(contactScreen, {display: 'block'})
			.fromTo(bg, {opacity: 0, duration: 0.3}, {opacity: 0.95}, "sameTime")
			.from(closeBtn, {rotate: '180deg', scale: 0, duration: 0.3,}, "sameTime+=0.2")
			.from(splitTextTitle2.words, {yPercent: 100, stagger: 0.08, duration: 0.7,}, "sameTime+=0.2")
			.from(splitTextMessage2.words, {yPercent: 100, stagger: 0.04, duration: 0.4,}, "sameTime+=0.6")
			.from(links, {filter: "blur(5px)", opacity: 0, stagger: 0.1, duration: 0.8,}, "sameTime+=1")


	let contactConcealTL = gsap.timeline({
		paused: true,
		defaults: {ease: 'primary-ease'},
		onStart: () => {
			contactScreen.classList.remove('active');
		},
	});

	contactConcealTL
			.to(closeBtn, {rotate: '180deg', scale: 0, duration: 0.3,}, "sameTime")
			.to([title, message, links], {opacity: 0, duration: 0.5, stagger: 0.05}, "sameTime")
			.to(contactScreen, {opacity: 0, duration: 0.5, ease: 'power4.in'}, "sameTime+=0.1")
			.set(contactScreen, {display: 'none'})

	contactBtn.forEach(function (link) {
		link.addEventListener("click", (e) => {
			contactConcealTL.progress(0).pause();
			contactRevealTL.restart();
		});
	})

	closeBtn.addEventListener("click", () => {
		contactConcealTL.play();
	});

	closeBtn.addEventListener("mouseover", event => {
		gsap.to(closeBtnInner, {scale: 1.1,opacity:0.5, duration:0.3, ease:'power2.inOut'});
	});

	closeBtn.addEventListener("mouseout", event => {
		gsap.to(closeBtnInner, {scale: 1, opacity:1, duration:0.3, ease:'power2.inOut'});
	});

}