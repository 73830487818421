/*! ABOUT */
function footerVideoFix() {
	const bgVid = document.getElementById('bg-video');
	const main = document.getElementById('#main-content');
	gsap.to(bgVid, {
		autoAlpha:0,
		/*yPercent:-100,*/
		ease: 'power2.inOut',
		scrollTrigger: {
			trigger: '#main-content > div',
			scrub: true,
			start: 'bottom 160%',
			end: 'bottom 60%',
			/*markers:true,*/
			pin: false,
			invalidateOnRefresh: true
		},
	});

}

function animateLogo() {
	var logoTL = gsap.timeline({paused:true});

	logoTL
			//.from('#text-outlines path', {duration: 2, drawSVG:0, stagger:0.02, ease:'primary-ease'}, 'sameTime')
			.from('#text-fill path', {duration: 0.4, scaleY:0, autoAlpha:0, stagger:0.05, ease:'primary-ease-out'})
			.to('#your-logo', {duration: 0.4, scale:1.3, rotate: '+=6', repeat: 1, yoyo: true, ease:'primary-ease-out'})
			.to('#your-logo', {duration: 0.4, scale:1.3, rotate: '-=6', repeat: 1, yoyo: true, ease:'primary-ease-out'})
			.from('#subtitle', {duration: 0.4, autoAlpha:0, yPercent:100, ease:'primary-ease-out'})


	ScrollTrigger.create({
		trigger: '.client-grid .your-logo',
		start: 'bottom bottom',
		/*markers:true,*/
		onEnter: () => { logoTL.play()}
	});


}

function initAboutHeroClickScroll() {
	let hero = document.getElementById('project-hero');
	let clientsSection = document.getElementById('clients-partners');

	hero.addEventListener('click', () => {
		gsap.to(window, {
			duration: 1.5,
			ease: 'primary-ease',
			scrollTo: clientsSection
		});
	});
}