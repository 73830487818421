function buildWorks() {

	const portfolioContainer = document.getElementById("projects-list");
	const portfolioMedia = document.getElementById("projects-media");

	PROJECT_DATA.sort((a, b) => a.worksPageOrder - b.worksPageOrder);

	// Loop through the array and create HTML elements for each portfolio item
	PROJECT_DATA.forEach((projectItem, index) => {

		// Create a div for each portfolio item
		const itemDiv = document.createElement("div");
		itemDiv.className = "works-item";

		//Create an anchor for each portfolio item
		const anchor = document.createElement("a");
		anchor.className = "local";
		anchor.href = projectItem.href;

		//add div for each string of text
		const textDiv = document.createElement("div");
		textDiv.className = "item-text";


		//add div for title and desc
		const titleDescDiv = document.createElement("div");
		titleDescDiv.className = "item-title-desc";
		textDiv.appendChild(titleDescDiv);

		// Add the name
		const name = document.createElement("h3");
		name.textContent = projectItem.name;
		titleDescDiv.appendChild(name);

		// Add the description
		const description = document.createElement("p");
		description.className = "description";
		description.textContent = projectItem.description;
		titleDescDiv.appendChild(description);

/*		const disciplinesDiv = document.createElement("div");
		disciplinesDiv.className = "item-disciplines";
		textDiv.appendChild(disciplinesDiv);
		const disciplines = document.createElement("p");
		disciplines.className = "disciplines";
		disciplines.textContent = projectItem.disciplines.join(' • ');
		disciplinesDiv.appendChild(disciplines);*/

		const recognitionDiv = document.createElement("div");
		recognitionDiv.className = "item-recognition";
		textDiv.appendChild(recognitionDiv);
		const recognition = document.createElement("p");
		recognition.className = "recognition";
		recognition.innerHTML = projectItem.recognition
		recognitionDiv.appendChild(recognition);

		//create images that appear on mobile bp
		const mobImgDiv = document.createElement("div");
		const mobImg = document.createElement("img");
		mobImgDiv.className = "item-media item-" + index;
		mobImg.src = '../' + projectItem.img16to9;
		mobImg.alt = projectItem.name;
		mobImgDiv.appendChild(mobImg);

		//create videos for desktop hover
		const video = document.createElement('video');
		video.className = "item-video item-" + index;
		video.muted = true;
		video.loop = true;
		video.playsInline = true; // playsInline in camelCase for JavaScript
		const videoSource = document.createElement('source');
		videoSource.src = '../' + projectItem.worksVideo; // Replace with the path to your video file
		videoSource.type = 'video/mp4';
		video.appendChild(videoSource);
		portfolioMedia.appendChild(video);

		itemDiv.appendChild(anchor);
		anchor.appendChild(mobImgDiv);
		anchor.appendChild(textDiv);
		portfolioContainer.appendChild(itemDiv);
	});

	// Function to insert an <hr> between each div
/*	const divs = portfolioContainer.querySelectorAll('.works-item');
	divs.forEach((div, index) => {
		if (index < divs.length) {
			const hr = document.createElement('hr');
			portfolioContainer.insertBefore(hr, div);
		}
	});*/


	worksInteraction();

}

function worksInteraction() {
	let projectList = document.getElementById('projects-list');
	let worksItem = document.querySelectorAll('.works-item');
	let projectsMedia = document.getElementById('projects-media');

	//hover
	mm.add("(min-width: 1024px)", () => {
		worksItem.forEach(function (item, index) {
			let xTo = gsap.quickTo(projectsMedia, "x", {duration: 0.8, ease: "power3"});
			let yTo = gsap.quickTo(projectsMedia, "y", {duration: 0.8, ease: "power3"});

			let itemVideo = projectsMedia.getElementsByClassName('item-' + index);
			let itemVideos = document.querySelectorAll('video.item-video');
			let itemAnchor = item.querySelector('a');
			let itemText = itemAnchor.querySelectorAll('.item-text > .item-title-desc > *');
			let itemDisciplines = itemAnchor.querySelector('.item-disciplines');
			let itemRecognition = itemAnchor.querySelector('.item-recognition');

			gsap.set(itemVideo, {autoAlpha: 0, scale: 1.5});
			gsap.set(projectsMedia, {xPercent: -50, yPercent: -50});


			let hoverTl = gsap.timeline({
				paused: true, defaults: {ease: 'primary-ease'}
			});

			hoverTl
					.to(itemVideo, {autoAlpha: 1, scale: 1}, 'sameTime')
					.to(itemText, {x: '3vw', scale: 1.2, transformOrigin: 'left', stagger: 0.05}, 'sameTime')
					.to(itemRecognition, {autoAlpha: 0}, 'sameTime')
					.to(itemAnchor, {backgroundColor: dark, color: cream}, 'sameTime')


			window.addEventListener("mousemove", (e) => {
				xTo(e.clientX);
				yTo(e.clientY);
			});


			function handleMouseEnter() {
				projectList.classList.add('active');
				hoverTl.play();
				if (itemVideos[index].paused) {
					itemVideos[index].play();
				}
			}

			function handleMouseLeave() {
				projectList.classList.remove('active');
				hoverTl.reverse();
				if (!itemVideos[index].paused) {
					itemVideos[index].pause();
				}
			}


			item.addEventListener("mouseenter", event => {
				handleMouseEnter();
			});

			item.addEventListener("mouseleave", event => {
				handleMouseLeave();
			});

		})
	});

	gsap.fromTo(worksItem,
			{
				opacity: 0,
				y: 50,
				ease: 'primary-ease',
				duration: 0.8
			},
			{
				y: 0,
				opacity: 1,
				delay: 2,
				stagger: 0.05
			}
	);

}

function populateOtherProjects() {
	function getCurrentPageName() {
		const path = window.location.pathname;
		const page = path.split('/').pop(); // Get the last part of the path
		return `/works/${page}`; // Include the leading slash to match the "href" format in the array
	}
	const currentPage = getCurrentPageName();
	const currentIndex = PROJECT_DATA.findIndex(project => project.href === currentPage);
	const otherProjects = PROJECT_DATA.filter((_, index) => index !== currentIndex);



	// Function to pick two random, different projects
	function pickTwoRandomProjects(projects) {
		if (projects.length < 2) {
			throw new Error("Not enough projects to choose from.");
		}

		const firstIndex = Math.floor(Math.random() * projects.length);
		let secondIndex;

		// Ensure the second index is different from the first
		do {
			secondIndex = Math.floor(Math.random() * projects.length);
		} while (secondIndex === firstIndex);

		return [projects[firstIndex], projects[secondIndex]];
	}



// Get two random projects
	const [project1, project2] = pickTwoRandomProjects(otherProjects);

	//create videos for Other Projects
	const video = document.createElement('video');
	video.className = "video";
	video.muted = true;
	video.loop = true;
	video.playsInline = true; // playsInline in camelCase for JavaScript
	const videoSource = document.createElement('source');
	videoSource.type = 'video/mp4';
	videoSource.src = '../' + project1.worksVideo;
	video.appendChild(videoSource);

	const video2 = document.createElement('video');
	video2.className = "video";
	video2.muted = true;
	video2.loop = true;
	video2.playsInline = true; // playsInline in camelCase for JavaScript
	const video2Source = document.createElement('source');
	video2Source.type = 'video/mp4';
	video2Source.src = '../' + project2.worksVideo;
	video2.appendChild(video2Source);


	//build Other Projects
	const project1Href = document.querySelector('#other-projects .project:nth-child(1) > a');
	const project1MediaContainer = document.querySelector('#other-projects .project:nth-child(1) > a > div:first-of-type');
	const project1Img = document.querySelector('#other-projects .project:nth-child(1) .image img');
	const project1Title = document.querySelector('#other-projects .project:nth-child(1) h3');
	const project1Desc = document.querySelector('#other-projects .project:nth-child(1) .desc p');
	project1MediaContainer.appendChild(video);
	project1Href.href = project1.href;
	project1Img.src = '../' + project1.img16to9;
	project1Img.alt = project1.name;
	project1Title.textContent = project1.name;
	project1Desc.textContent = project1.description;

	const project2Href = document.querySelector('#other-projects .project:nth-child(2) > a');
	const project2MediaContainer = document.querySelector('#other-projects .project:nth-child(2) > a > div:first-of-type');
	const project2Img = document.querySelector('#other-projects .project:nth-child(2) .image img');
	const project2Title = document.querySelector('#other-projects .project:nth-child(2) h3');
	const project2Desc = document.querySelector('#other-projects .project:nth-child(2) .desc p');
	project2MediaContainer.appendChild(video2);
	project2Href.href = project2.href;
	project2Img.src = '../' + project2.img16to9;
	project2Img.alt = project2.name;
	project2Title.textContent = project2.name;
	project2Desc.textContent = project2.description;

	otherProjectsInteraction();

}


function otherProjectsInteraction() {
	//hover states
	mm.add("(min-width: 1024px)", () => {

		let otherProject = document.querySelectorAll('#other-projects .project');

		otherProject.forEach(function (item, index) {
			let image = item.querySelector('.image');
			let video = item.querySelector('.video');

			let hoverTl = gsap.timeline({
				paused: true, defaults: {ease: 'primary-ease'}
			});

			hoverTl.set(video, {scale:1.2});

			hoverTl
					.to(image, {autoAlpha: 0, scale: 1.2}, 'sameTime')
					.to(video, {scale: 1}, 'sameTime')


			function handleMouseEnter() {
				if (video.paused) {
					video.play();
				}
				hoverTl.play();
			}

			function handleMouseLeave() {
				if (!video.paused) {
					video.pause();
				}
				hoverTl.reverse();
			}


			item.addEventListener("mouseenter", event => {
				handleMouseEnter();
			});

			item.addEventListener("mouseleave", event => {
				handleMouseLeave();
			});

		})
	});

	//reveal on scroll
	const otherProjectsItems = document.querySelectorAll('#other-projects .projects');
	gsap.from(otherProjectsItems, {
		opacity: 0,
		scale: 1.2,
		ease: 'primary-ease',
		stagger: 0.2,
		duration: 0.8,
		scrollTrigger: {
			trigger: otherProjectsItems,
			start: "top 70%",
			scrub: false,
			pin: false,
			/*markers: true*/
		},
	});


}


