/*! GLOBAL */
let homeRevealTL;
let heroRevealTl;
let aboutPageRevealTl;
let textAniTL;

const cursor = document.getElementById('cursor');
const cursorText = cursor.querySelector('span');
const body = document.getElementsByTagName('body')[0];
const header = document.getElementById('main-nav');
const underline = header.querySelector('.underline');
const currentLine = header.querySelector('.current-line');
const footer = document.getElementById('main-footer');
const main = document.querySelector('#main-content');
const otherProjects = document.getElementById('other-projects');
let templateName;
const dark = '#171717';
const cream = '#F4F3EC';


const homeHero = document.querySelector('#hero');
const homeFeaturedGrid = document.getElementById('featured-grid');
const homeProjectsGrid = document.getElementById('projects-grid');

CustomEase.create("primary-ease", "0.62, 0.01, 0.01, 0.99");
CustomEase.create("primary-ease-out", ".34, 1.56, 0.64, 1");
let staggerDefault = 0.07;
let durationDefault = 1.47;
let durationDefaultFaster = 1.2;
let mm = gsap.matchMedia();

const entranceOverlay = document.getElementById('entrance-overlay');
const entranceMask = document.getElementById('text-mask');

window.addEventListener('pageshow', (event) => {
	// Check if the page was loaded from the cache
	if (event.persisted) {
		// Hide the overlay when the page is loaded from cache
		entranceOverlay.classList.add('hide');
		pageExit();
	}
});


function pageEntrance() {
	let greetingOptions = ["Hello", "Hi", "Hey", "What's up", "Alright?", "Ahoy!"];
	let bgColor = ["#FF5733", "#33FF57", "#3357FF", "#FF33A1", "#FF8F33", "#33FFD2", "#8F33FF", "#FFEB33", "#FF3333", "#33FF8F"];
	let textColor = ["#33FFCC", "#FF33CC", "#FFCC33", "#33FF57", "#3357FF", "#FF5733", "#57FF33", "#3333FF", "#33CCFF", "#FF5733"];
	let chosenGreeting = Math.floor(Math.random() * greetingOptions.length);
	let chosenBGColor = Math.floor(Math.random() * bgColor.length);

/*	let welcomeText = document.querySelector('#text-splash h1.greeting').innerHTML = greetingOptions[chosenGreeting];
	let welcomeTextColor = document.querySelector('#text-splash h1.greeting').style.color = textColor[chosenBGColor];
	let backgroundColor = document.querySelector('#text-splash').style.backgroundColor = bgColor[chosenBGColor];*/

	//let textLines = new SplitText('#text-splash h1', {type: "chars"});
	//let textLines2 = new SplitText('#text-splash h1', {type: "chars", charsClass: "chars"});

	const pageEntranceTl = gsap.timeline({
		paused: true, onComplete: () => {
			entranceOverlay.classList.add('hide');
			gsap.set('#text-mask > rect', {clearProps: 'all'})
		}, defaults: {ease: 'primary-ease'}
	});
	pageEntranceTl
			.set('#text-splash path', {display: 'block'})
			.from('#text-splash path',{
				yPercent: 110,
				stagger: 0.06,
				duration: 0.7,
				ease: 'primary-ease',})
			.add(function () {
				playPageReveal();
			}, '+=0.5')
/*			.to('#text-splash path',{
				yPercent: -110,
				stagger: 0.04,
				duration: 0.3,
				ease: 'power5.in',
			},'<')*/
			.to('#text-mask > rect:nth-of-type(odd)', {
				scaleY: 0,
				duration: 0.5,
				transformOrigin: 'top',
				stagger: {amount: 0.2, from: 'random'}
			}, '<+=0.1')
			.to('#text-mask > rect:nth-of-type(even)', {
				scaleY: 0,
				x: -1,
				duration: 0.5,
				transformOrigin: 'top',
				stagger: {amount: 0.2, from: 'random'}
			}, '<+=0.1')

	pageEntranceTl.play();

}

function playPageReveal() {
	if (whatTemplate('home')) {
		homeRevealTL.play();
	}
	if (whatTemplate('project')) {
		heroRevealTl.play();
	}
	if (whatTemplate('works')) {
		heroRevealTl.play();
	}
	if (whatTemplate('about')) {
		heroRevealTl.play();
	}
}
document.addEventListener('DOMContentLoaded', function () {
	pageEntrance();
});


window.addEventListener('load', function () {
	gsap.registerPlugin(ScrollTrigger, SplitText, ScrollToPlugin, CustomEase);

	if (whatTemplate('home')) {
		createFeaturedGrid();
		homePageReveal();
		initHomeHeroScroll();
		initHomeHeroClickScroll();
		setHeroTickerHeight();
		window.onresize = (event) => {
			setHeroTickerHeight();
		};

	}

	if (whatTemplate('about')) {
		heroReveal();
		footerVideoFix();
		animateLogo();
		initAboutHeroClickScroll();
	}

	if (whatTemplate('project')) {
		projectImagesReveal();
		heroReveal();
		draggableImages();
		dragPanImages();
		videoInView();
		populateOtherProjects();
	}

	if (whatTemplate('works')) {
		heroReveal();
		buildWorks();
	}

	if(whatTemplate('error-404')) {
		/*error404Video();*/
	}


	whatTemplate();
	initMouseCursor();
	initSmoothScroll();
	initHeader();
	initFooter();
	revealChars();
	revealLines();
	pageExit();
	fadeIns();
	contactOverlay();
	underlineActivePage();

})

function pageExit() {
	const localLinks = document.querySelectorAll('.local');
	let destination;

	localLinks.forEach(function (url) {
		url.addEventListener("click", (e) => {
			e.preventDefault();
			destination = url.getAttribute("href");
			pageExitTl.play();
		});
	})

	const pageExitTl = gsap.timeline({
		paused: true,
		onStart: () => {
			entranceOverlay.classList.remove('hide')
			gsap.set('#text-mask > rect', {transformOrigin: 'right', x: 1, scaleY: 0})
			gsap.set('#text-splash path', {display: 'none'})
		},
		onComplete: () => {
			//window.open(destination, '_self');
			window.location.href = destination;

		}, defaults: {ease: 'primary-ease'}
	});

	pageExitTl
			.to('#text-mask > rect:nth-of-type(odd)', {
				scaleY: 1,
				x: 0,
				duration: 0.5,
				stagger: {amount: 0.2, from: 'random'}
			}, 'sameTime')
			.to('#text-mask > rect:nth-of-type(even)', {
				scaleY: 1,
				x: 0,
				duration: 0.5,
				transformOrigin: 'left',
				stagger: {amount: 0.2, from: 'random'}
			}, 'sameTime')

}

/* whatTemplate */
function whatTemplate(name) {
	templateName = body.dataset.template;
	return (name === templateName);

}


/* Lenis Smooth Scroll */
function initSmoothScroll() {
	const lenis = new Lenis()

	function raf(time) {
		lenis.raf(time)
		requestAnimationFrame(raf)
	}

	requestAnimationFrame(raf)
}

/* Main Header */
function initHeader() {

	//reveal for header on page load
	let headerChildren = gsap.utils.toArray('.header-reveal', header);
	const headerTL = gsap.timeline({
		paused: true, onComplete: () => {

		}
	});
	headerTL.from(headerChildren, {
		duration: 0.5,
		yPercent: -80,
		autoAlpha: 0,
		stagger: 0.09,
		delay: 0.5
	})

	headerTL.play();
}

function underlineActivePage() {
	const currentPage = window.location.pathname.replace(/^\/|\/$/g, '');
	const navLinks = document.querySelectorAll('#main-nav nav .local');

	/*console.log(currentPage)*/

	navLinks.forEach(link => {
		const linkHref = link.getAttribute('href').replace(/^\/|\/$/g, '');

		/*console.log(linkHref)*/

		if (currentPage === linkHref) {
			let parent = link.parentElement;
			parent.classList.add('current-page');
		}
	});

}

/* Hero Element used on Work, Projects and About*/
function heroReveal() {

	//Project Page elements
	const hero = document.getElementById('project-hero');
	const mainTitle = hero.querySelector('h1 > span');
	const mainTitleChars = new SplitText(mainTitle, {type: "chars, lines", charsClass: "chars"});
	const mainTitleChars2 = new SplitText(mainTitle, {type: "chars"});
	const firstMediaBlock = document.querySelector('section.project-media-block');

	/*console.log(firstMediaBlock)*/

	const subtitle = hero.querySelector('.subtitle > span');
	const intro = hero.querySelectorAll('.passage > p');
	const introTextLines = new SplitText(intro, {type: "lines", linesClass: "single-line"});
	new SplitText(intro, {type: "lines"});
	heroRevealTl = gsap.timeline({
		paused: true, onComplete: () => {
			mainTitleChars2.revert();
			mainTitleChars.revert();
			introTextLines.revert();
		}, defaults: {ease: 'primary-ease'}
	});

	heroRevealTl
			.from(mainTitleChars2.chars, {
				yPercent: 90,
				stagger: 0.04,
				duration: 0.7
			}, '<')
			.from(subtitle, {
				yPercent: 90,
				duration: 1,
				stagger: 0.3
			}, '<+=0.2');

			if(introTextLines.lines.length !== 0) {
				heroRevealTl.from(introTextLines.lines, {
					duration: 0.8,
					yPercent: 90,
					stagger: 0.05
				}, '<+=0.2');
			}

			if (firstMediaBlock !== null) {
				heroRevealTl.from(firstMediaBlock, {
					duration: 0.8,
					opacity:0
				}, '<+=0.2');
			}

}

/* Main Footer */
function initFooter() {
	/*console.log('FOOTER')*/
	const largeText = footer.querySelector('p.main');
	const signoffText = footer.querySelector('p.signoff');
	const bottomMenu = footer.querySelector('.footer-bottom-menu');

//mobile footer ani
	mm.add("(max-width: 1023px)", () => {
		gsap.from(largeText, {
			filter: 'blur(3px)',
			opacity: 0,
			scale: 1.2,
			/*rotate: 8,*/
			y: 100,
			ease: "power2.Out",
			scrollTrigger: {
				trigger: main,
				start: "bottom bottom-=150",
				end: "bottom bottom-=300",
				scrub: true,
				pin: false,
				/*markers: true,*/
				invalidateOnRefresh: true,
				onEnter: () => whatTemplate('home') ? (homeHero.style.display = 'none') : null,
				onLeaveBack: () => whatTemplate('home') ? (homeHero.style.display = 'flex') : null
			},
		});

		gsap.from([signoffText, bottomMenu], {
			filter: 'blur(3px)',
			opacity: 0,
			y: 50,
			ease: "power4.Out",
			/*stagger: 0.5,*/
			scrollTrigger: {
				trigger: main,
				start: "bottom bottom-=150",
				end: "bottom bottom-=300",
				scrub: true,
				pin: false,
				/*markers: true,*/
				invalidateOnRefresh: true
			},
		});
	});

	//desktop footer ani
	mm.add("(min-width: 1024px)", () => {
		gsap.from(largeText, {
			filter: 'blur(3px)',
			opacity: 0,
			scale: 1.2,
			/*rotate: 8,*/
			yPercent: 200,
			ease: "power2.Out",
			scrollTrigger: {
				trigger: main,
				start: "bottom bottom",
				end: "bottom bottom-=500",
				scrub: true,
				pin: false,
				/*markers: true,*/
				invalidateOnRefresh: true,
				onEnter: () => whatTemplate('home') ? (homeHero.style.display = 'none') : null,
				onLeaveBack: () => whatTemplate('home') ? (homeHero.style.display = 'flex') : null
			},
		});

		gsap.from([signoffText, bottomMenu], {
			filter: 'blur(3px)',
			opacity: 0,
			y: 50,
			ease: "power4.Out",
			/*stagger: 0.5,*/
			scrollTrigger: {
				trigger: main,
				start: "bottom bottom-=300",
				end: "bottom bottom-=500",
				scrub: true,
				pin: false,
				/*markers: true,*/
				invalidateOnRefresh: true
			},
		});

	});

}

/* Mouse Cursor */
function initMouseCursor() {
	const footerCTA = footer.querySelector('.large-cta');
	let showCustomText = false;


	mm.add('(min-width: 1024px)', () => {
		gsap.set('#cursor', {xPercent: -50, yPercent: -50});

		let xTo = gsap.quickTo(cursor, "x", {duration: 0.8, ease: "power3"}),
				yTo = gsap.quickTo(cursor, "y", {duration: 0.8, ease: "power3"});

		window.addEventListener("mousemove", (e) => {
			xTo(e.clientX);
			yTo(e.clientY);
		});
	});

	function resetCursorText(target) {
		showCustomText = false;
		cursor.classList.remove('active');
		target.classList.remove('cursorTextSet')
		gsap.to(cursor, 0.2, {scale:0, onComplete:()=>{
				if (!showCustomText) {
					cursorText.innerHTML = '';
				}
			}})

		function myFunction() {
			if (!showCustomText) {
				cursorText.innerHTML = '';
			}
		}
	}

	function setCursorText(text, target) {
		showCustomText = true;
		cursorText.innerHTML = text;
		cursor.classList.add('active');
		target.classList.add('cursorTextSet')
		gsap.to(cursor, 0.2, {scale:1})
	}

	footerCTA.addEventListener("mouseover", event => {
		setCursorText('Say Hi!', footerCTA);
	});

	footerCTA.addEventListener("mouseleave", event => {
		resetCursorText(footerCTA);
	});


	if (whatTemplate('home')) {
		let homeGridItems = homeFeaturedGrid.querySelectorAll('.grid-item');
		homeGridItems.forEach(function (item) {
			item.addEventListener("mouseover", event => {
				setCursorText('View Project', item);
			});
			item.addEventListener("mouseleave", event => {
				resetCursorText(item);
			});
		});

		homeHero.addEventListener("mouseover", event => {
			setCursorText('View Featured Work', homeHero);
		});

		homeHero.addEventListener("mouseleave", event => {
			resetCursorText(homeHero);
		});

	}

	if (whatTemplate('project')) {
		let otherProjectItems = otherProjects.querySelectorAll('.project');
		let dragPic = document.querySelectorAll('.dragPic');
		let featureVideo = document.querySelectorAll('.feature-video');
		let dragPanPic = document.querySelectorAll('.drag-pan-pic');

		otherProjectItems.forEach(function (project) {
			project.addEventListener("mouseover", event => {
				setCursorText('View Project', project);
			});
			project.addEventListener("mouseleave", event => {
				resetCursorText(project);
			});
		});

		dragPic.forEach(function (pic) {
			pic.addEventListener("mouseover", event => {
				setCursorText('Drag Me', pic);
			});
			pic.addEventListener("mouseleave", event => {
				resetCursorText(pic);
			});
		});

		dragPanPic.forEach(function (pic) {
			pic.addEventListener("mouseover", event => {
				setCursorText('Drag To Explore', pic);
			});
			pic.addEventListener("mouseleave", event => {
				resetCursorText(pic);
			});
		});


		featureVideo.forEach(function (vid) {
			let mainVid = vid.querySelector('.main-video');
			let isPlaying = false;

			mainVid.addEventListener("playing", (event) => {
				isPlaying = true;
			});

			vid.addEventListener("mouseover", event => {
				if (!isPlaying) {
					setCursorText('Play Me', vid);
				}
			});
			vid.addEventListener("mouseleave", event => {
				resetCursorText(vid);
			});
		});


	}

	if (whatTemplate('about')) {
		let hero = document.getElementById('project-hero');

		hero.addEventListener("mouseover", event => {
			setCursorText('Learn More', hero);
		});
		hero.addEventListener("mouseleave", event => {
			resetCursorText(hero);
		});

	}

}

/* Text - chars Reveal */
function revealChars() {
	let revealChars = gsap.utils.toArray('.reveal-chars');

	for (let i = 0; i < revealChars.length; i++) {
		let splitTextLines = new SplitText(revealChars[i], {type: "lines"});
		let splitTextLines2 = new SplitText(revealChars[i], {type: "chars"});

		gsap.from(splitTextLines2.chars, {
			yPercent: 100,
			stagger: 0.02,
			duration: 0.7,
			ease: 'primary-ease',
			onComplete: () => {
				revert()
			},
			scrollTrigger: {
				trigger: revealChars[i],
				start: "top 70%",
				pin: false,
				onLeave: () => {

				}
			},
		});

		function revert() {
			splitTextLines2.revert(splitTextLines2)
			splitTextLines.revert(splitTextLines)
		}


	}

}

/* Text - lines Reveal */
function revealLines() {
	let revealLines = gsap.utils.toArray('.reveal-lines');

	for (let i = 0; i < revealLines.length; i++) {
		let splitTextLines = new SplitText(revealLines[i], {type: "lines"});
		new SplitText(revealLines[i], {type: "lines"});

		gsap.from(splitTextLines.lines, {
			yPercent: 100,
			stagger: 0.08,
			duration: 0.7,
			ease: 'primary-ease',
			onComplete: () => {
				splitTextLines.revert()
			},
			scrollTrigger: {
				trigger: revealLines[i],
				start: "top 70%",
				pin: false,
				onLeave: () => {

				}
			},
		});
	}

}

/* Text - simple fade in */
function fadeIns() {
	let fadeIn = gsap.utils.toArray('.fade-in');

	fadeIn.forEach(function (item) {
		gsap.from(item, {
			autoAlpha: 0,
			duration: 0.7,
			ease: 'primary-ease',
			onComplete: () => {
				gsap.set(item, {clearProps: 'all'})
			},
			scrollTrigger: {
				trigger: item,
				start: "top 80%",
				pin: false
			},
		});
	});


}


/* 404 video effect*/
function error404Video() {
	document.addEventListener('mousemove', function (e) {
		const video = document.getElementById('video');

		// Get the width of the browser viewport
		const viewportWidth = window.innerWidth;

		// Calculate the current mouse X position as a percentage of the viewport width
		const mouseX = e.clientX;
		const percentage = mouseX / viewportWidth;

		// Update the video's current time based on the percentage
		const videoDuration = video.duration;
		video.currentTime = percentage * videoDuration;
	});
}