const PROJECT_DATA = [
	{//0
		"name": "Amplience",
		"disciplines": ["Design", "Art Direction"],
		"recognition": "",
		"description": "Logo Design for the leading AI-driven content management company",
		"homeFeaturedImg" : "images/global/img-1-1/amplience-logo-design-1-1.webp",
		"imgSquare": "images/global/img-1-1/amplience-logo-design-1-1.webp",
		"img16to9": "images/global/img-16-9/amplience-logo-design-16-9.webp",
		"worksVideo": "images/global/video-16-9/amplience-logo-design-16-9.mp4",
		"homeFeaturedVideo": "images/global/video-16-9/amplience-logo-design-16-9.mp4",
		"href": "/works/amplience-logo-design",
		"worksPageOrder": 6
	},
	{//1
		"name": "Amplience",
		"disciplines": ["Art Direction", "Design", "Web Development", "Digital Experience"],
		"recognition": "",
		"description": "360 Brand Identity for the leading AI-driven content management company",
		"homeFeaturedImg" : "images/global/img-1-1/amplience-brand-identity-1-1.webp",
		"imgSquare": "images/global/img-1-1/amplience-brand-identity-1-1.webp",
		"img16to9": "images/global/img-16-9/amplience-brand-identity-16-9.webp",
		"worksVideo": "images/global/video-16-9/amplience-brand-identity-16-9.mp4",
		"homeFeaturedVideo": "images/global/video-16-9/amplience-brand-identity-16-9.mp4",
		"href": "/works/amplience-brand-identity",
		"worksPageOrder": 1
	},
	{//2
		"name": "Amplience",
		"disciplines": ["Design", "Web Development", "Digital Experience"],
		"recognition": "",
		"description": "Brand Portal for the leading AI-driven content management company",
		"homeFeaturedImg" : "images/global/img-9-16/amplience-brand-portal-9-16.webp",
		"imgSquare": "images/global/img-1-1/amplience-brand-portal-1-1.webp",
		"img16to9": "images/global/img-16-9/amplience-brand-portal-16-9.webp",
		"worksVideo": "images/global/video-16-9/amplience-brand-portal-16-9.mp4",
		"homeFeaturedVideo": "images/global/video-9-16/amplience-brand-portal-9-16.mp4",
		"href": "/works/amplience-brand-portal",
		"worksPageOrder": 9
	},
	{//3
		"name": "Amazon Prime",
		"disciplines": ["Design", "Web Development", "Digital Experience"],
		"recognition": "",
		"description": "Campaigns for the worlds largest online retailer.",
		"homeFeaturedImg" : "",
		"imgSquare": "images/global/img-1-1/amazon-prime-1-1.webp",
		"img16to9": "images/global/img-16-9/amazon-prime-16-9.webp",
		"worksVideo": "images/global/video-16-9/amazon-prime-16-9.mp4",
		"homeFeaturedVideo": "images/global/video-16-9/amazon-prime-16-9.mp4",
		"href": "/works/amazon-prime-campaigns",
		"worksPageOrder": 7
	},
	{//4
		"name": "Amazon XCM",
		"disciplines": ["Design", "Art Direction", "3d", "Compositing"],
		"recognition": "",
		"description": "Collaboration with Amazon's internal Cross-Channel Marketing Team.",
		"homeFeaturedImg" : "",
		"imgSquare": "images/global/img-1-1/amazon-xcm-1-1.webp",
		"img16to9": "images/global/img-16-9/amazon-xcm-16-9.webp",
		"worksVideo": "images/global/video-16-9/amazon-xcm-16-9.mp4",
		"homeFeaturedVideo": "images/global/video-16-9/amazon-xcm-16-9.mp4",
		"href": "/works/amazon-xcm",
		"worksPageOrder": 8
	},
	{//5
		"name": "Amazon Climate Pledge",
		"disciplines": ["Design", "Art Direction", "3d", "Compositing"],
		"recognition": "",
		"description": "Working with Amazon to achieve net-zero carbon emissions by 2040.",
		"homeFeaturedImg" : "images/global/img-1-1/amazon-climate-pledge-1-1.webp",
		"imgSquare": "images/global/img-1-1/amazon-climate-pledge-1-1.webp",
		"img16to9": "images/global/img-16-9/amazon-climate-pledge-16-9.webp",
		"worksVideo": "images/global/video-16-9/amazon-climate-pledge-16-9.mp4",
		"homeFeaturedVideo": "images/global/video-16-9/amazon-climate-pledge-16-9.mp4",
		"href": "/works/amazon-climate-pledge",
		"worksPageOrder": 10
	},
	{//6
		"name": "Titus Klein",
		"disciplines": ["Brand Identity", "Design", "Digital Experience"],
		"recognition": "<b>Honorable Mention</b><br>awwwards.com",
		"description": "Head of digital design with this San Francisco based design agency.",
		"homeFeaturedImg" : "images/global/img-9-16/titus-klein-9-16.webp",
		"imgSquare": "images/global/img-1-1/titus-klein-1-1.webp",
		"img16to9": "images/global/img-16-9/titus-klein-16-9.webp",
		"worksVideo": "images/global/video-16-9/titus-klein-16-9.mp4",
		"homeFeaturedVideo": "images/global/video-9-16/titus-klein-9-16.mp4",
		"href": "/works/titus-klein",
		"worksPageOrder": 2
	},
	{//7
		"name": "Teforia",
		"disciplines": ["Brand Identity", "Art Direction"],
		"recognition": "<b>Honorable Mention</b><br>awwwards.com",
		"description": "Digital experience for a smart tea infuser",
		"homeFeaturedImg" : "images/global/img-16-9/teforia-16-9.webp",
		"imgSquare": "images/global/img-1-1/teforia-1-1.webp",
		"img16to9": "images/global/img-16-9/teforia-16-9.webp",
		"worksVideo": "images/global/video-16-9/teforia-16-9.mp4",
		"homeFeaturedVideo": "images/global/video-16-9/teforia-16-9.mp4",
		"href": "/works/teforia",
		"worksPageOrder": 4
	},
	{//8
		"name": "Molekule",
		"disciplines": ["Brand Identity", "Art Direction"],
		"recognition": "<b>Site of the Day</b><br>awwwards.com",
		"description": "Digital experience for a leader in air purification technology",
		"homeFeaturedImg" : "images/global/img-16-9/molekule-16-9.webp",
		"imgSquare": "images/global/img-1-1/molekule-1-1.webp",
		"img16to9": "images/global/img-16-9/molekule-16-9.webp",
		"worksVideo": "images/global/video-16-9/molekule-16-9.mp4",
		"homeFeaturedVideo": "images/global/video-16-9/molekule-16-9.mp4",
		"href": "/works/molekule",
		"worksPageOrder": 3
	},
	{//9
		"name": "Madhouse",
		"disciplines": ["3d", "Motion", "Compositing", "Art Direction"],
		"recognition": "",
		"description": "A hypnotic 3d experience",
		"homeFeaturedImg" : "images/global/img-16-9/madhouse-16-9.webp",
		"imgSquare": "images/global/img-1-1/madhouse-1-1.webp",
		"img16to9": "images/global/img-16-9/madhouse-16-9.webp",
		"worksVideo": "images/global/video-16-9/madhouse-16-9.mp4",
		"homeFeaturedVideo": "images/global/video-16-9/madhouse-16-9.mp4",
		"href": "/works/madhouse",
		"worksPageOrder": 11
	},
	{//10
		"name": "Abstract / Real",
		"disciplines": ["3d", "Compositing", "Art Direction"],
		"recognition": "",
		"description": "3d experiments with forms and textures",
		"homeFeaturedImg" : "",
		"imgSquare": "images/global/img-1-1/abstract-real-1-1.webp",
		"img16to9": "images/global/img-16-9/abstract-real-16-9.webp",
		"worksVideo": "images/global/video-16-9/abstract-real-16-9.mp4",
		"homeFeaturedVideo": "images/global/video-16-9/abstract-real-16-9.mp4",
		"href": "/works/abstract-real",
		"worksPageOrder": 12
	},
	{//11
		"name": "3d Typography",
		"disciplines": ["3d", "Compositing", "Art Direction"],
		"recognition": "",
		"description": "Explorations in 3D Design and Typography",
		"homeFeaturedImg" : "images/global/img-16-9/3d-typography-16-9.webp",
		"imgSquare": "images/global/img-1-1/3d-typography-1-1.webp",
		"img16to9": "images/global/img-16-9/3d-typography-16-9.webp",
		"worksVideo": "images/global/video-16-9/3d-typography-16-9.mp4",
		"homeFeaturedVideo": "images/global/video-16-9/3d-typography-16-9.mp4",
		"href": "/works/3d-typography",
		"worksPageOrder": 13
	},
	{//12
		"name": "Mandalas",
		"disciplines": ["3d", "Compositing", "Art Direction"],
		"recognition": "",
		"description": "Exploring intricate geometric patterns",
		"homeFeaturedImg" : "images/global/img-1-1/mandalas-1-1.webp",
		"imgSquare": "images/global/img-1-1/mandalas-1-1.webp",
		"img16to9": "images/global/img-16-9/mandalas-16-9.webp",
		"worksVideo": "images/global/video-16-9/mandalas-16-9.mp4",
		"homeFeaturedVideo": "images/global/video-16-9/mandalas-16-9.mp4",
		"href": "/works/mandalas",
		"worksPageOrder": 5
	}
]